import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from 'material-table';
import {
  GetActividade,GetCategorias
} from '../../utils/CategoriasManager';
import {
  CrearActividad
} from '../../utils/ActividadesHelper';
import { makeStyles } from '@material-ui/core/styles';
import ListItems from '../common/ListItem';
import {
  GetDia
} from '../../utils/DiasHelper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import FormHelperText from '@material-ui/core/FormHelperText';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Page(props) {
    
    const categorias = GetCategorias();
    const classes = useStyles();
    const {
        // goTo,
        // currentItem,
    } = props;
    const getHorarios=(horarios)=>{
      var stringHorario = "";
      horarios.map(h =>{
          stringHorario =stringHorario + GetDia(h.dia) +" de "+h.horaDesde+ " a "+h.horaHasta
      })
      return stringHorario;
  }

  const [id, setId] = React.useState(props.id + 1);
  const [horarios, setHorarios] = React.useState([]);
  const [horariosCant, setHorariosCant] = React.useState([1]);

  const [categoria, setCategoria] = React.useState(null);

  const [descripcion, setDescripcion] = React.useState(null);
  
  const [cupo, setCupo] = React.useState(null);

  //horarios
  const [horaDesde, setHoraDesde] = React.useState(null);
  const [horaHasta, setHoraHasta] = React.useState(null);
  const [dia, setDia] = React.useState(null);

const changeDescripcion=({target})=>{
    setDescripcion(target.value);
}
const changeCategoria=({target})=>{
  setCategoria(target.value);
}
const changeCupo=({target})=>{
    setCupo(target.value);
}
const changeDia=({target})=>{
    setDia(target.value);
}
const changeHoraDesde=({target})=>{
    setHoraDesde(target.value);
}
const changeHoraHasta=({target})=>{
    setHoraHasta(target.value);
}
function remove(array, index) {
    array.splice(index, 1);
  }
const EliminarHorario = id =>{
    console.log(1,horarios)


    let oldArray = horarios;
    let newArray = oldArray.filter((word,i) => i != id);      
    setHorarios(newArray);



}

const IrActividades= () =>{
  window.location.href = "/actividades";
  }
const CrearClase = () =>{
  if(!(categoria && cupo && descripcion && horarios.length > 0))
  {
    alert('Faltan cargar datos, asegurate poner al menos un horario');
    return ;
  }

  var formData = new FormData();

  formData.append("categoria", categoria);
  formData.append("cupo", cupo);
  formData.append("descripcion", descripcion);
  let nombre = categorias.filter(a => a.id ==categoria)[0].nombre;
  formData.append("nombre", nombre);

  formData.append("horarios", JSON.stringify(horarios));
  CrearActividad(formData);
  alert('Actividad creada exitosamente');


}

const AgregarHorario= () =>{
    if(horaDesde && horaHasta && dia){
        //Si ya hay un horario cargado y pide agregar otro, guardo el actual en el array de horarios y pusheo uno nuevo a la lista
        let oldArray = horariosCant;
        let newArray = oldArray.slice();
        newArray.push(1)           
        setHorariosCant(newArray);            
        let horario = {desde:horaDesde,hasta:horaHasta,dia}
        horarios.push(horario)
        setHorarios(horarios)

    } else      {
        alert('Faltan cargar datos en el horario anterior.');
    }      //props.setCurso();
}

    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{ marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>

            <div class="break" style={{flexBasis: '100%'}}></div> 
            <Fragment> 
            <FormControl variant="outlined"  className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Categoria</InputLabel>  
        <Select
       labelId="demo-simple-select-outlined-label"
       id="demo-simple-select-outlined"
       onBlur={changeCategoria}
        >
          {categorias.map((categoria, i) => ( <MenuItem value={categoria.id}>{categoria.nombre}</MenuItem>))}
        </Select>
        
      </FormControl>
      <FormControl variant="outlined"  className={classes.formControl}>
           
           <TextField id="outlined-basic" label="Descripcion" onBlur={changeDescripcion} variant="outlined"  placeholder="EJ: Instructor Pepe gonzalez" />
     </FormControl>
     <FormControl variant="outlined"  className={classes.formControl}>
           
           <TextField id="outlined-basic" label="Cupo" onBlur={changeCupo} variant="outlined" type="number" />
     </FormControl>
     </Fragment> 
         <div class="break" style={{flexBasis: '100%'}}></div> 
          {/* -----------Horarios ya cargados */}
         {horarios.map((horario, i) => ( <Fragment> <div>{GetDia(horario.dia)} desde {horario.desde} hasta {horario.hasta} - <span style={{color: 'red',fontBeight: 'bold', cursor:'pointer'}} onClick={() =>EliminarHorario(i)}>Eliminar</span></div><div class="break" style={{flexBasis: '100%'}}></div> </Fragment>))}
         <div class="break" style={{flexBasis: '100%'}}></div> 

          {/* -----------Horarios */}

       <Fragment>           
            <FormControl variant="outlined"  className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Dia</InputLabel>  
        <Select
       labelId="demo-simple-select-outlined-label"
       id="demo-simple-select-outlined"
       onBlur={changeDia}
        >
          <MenuItem value={1}>Lunes</MenuItem>
          <MenuItem value={2}>Martes</MenuItem>
          <MenuItem value={3}>Miercoles</MenuItem>
          <MenuItem value={4}>Jueves</MenuItem>
          <MenuItem value={5}>Viernes</MenuItem>
          <MenuItem value={6}>Sabado</MenuItem>
          <MenuItem value={7}>Domingo</MenuItem>
        </Select>
        
      </FormControl>
      <FormControl variant="outlined"  className={classes.formControl}>
      
            <TextField id="outlined-basic" label="Desde" onBlur={changeHoraDesde} variant="outlined" type="time"  placeholder="20:00" />
      
      </FormControl>
      <FormControl variant="outlined"  className={classes.formControl}>
           
            <TextField id="outlined-basic" label="Hasta" onBlur={changeHoraHasta} variant="outlined"  type="time"  placeholder="22:00" />
      </FormControl>
            <div style={{flexBasis: '100%', paddingTop:10}}></div> 
            </Fragment>
            <Button variant="contained" onClick={AgregarHorario} style={{width: '210px'}} color="primary" size="large" > Agregar dia</Button>
            <div style={{flexBasis: '100%', paddingTop:10}}></div> 
            <div>Acordate que para que el horario se guarde bien, tenes que tocar el boton agregar dia y tiene que aparecer en la lista de arriba</div> 
            <div style={{flexBasis: '100%', paddingTop:10}}></div> 

            <Button variant="contained" onClick={CrearClase} style={{width: '210px'}} color="primary" size="large" > Crear clase</Button>
            <div style={{flexBasis: '100%', paddingTop:10}}></div> 
            <div style={{flexBasis: '100%', paddingTop:10}}></div> 

            <div style={{flexBasis: '100%', paddingTop:10}}></div> 

            <div style={{flexBasis: '100%', paddingTop:10}}></div> 

            <div style={{flexBasis: '100%', paddingTop:10}}></div> 
            <Button variant="contained" onClick={IrActividades} style={{width: '210px'}} color="primary" size="large" >Ver actividades ya existentes</Button>


            </div>
        </Fragment>
    );
}

export default Page;