import {
    setItemStorage, getItemStorage,removeItemStorage
} from './LocalStorageManager';


export const UsuarioLogueado = () =>{
    return getItemStorage('usuario');
}

export const LoguearUsuario = (usuario) =>{
    setItemStorage('usuario',usuario);
    setItemStorage('token',usuario.token);
}
export const GetUserImg = () =>{
    return getItemStorage('userImg');
}

export const GetUserName= () =>{
    let user = getItemStorage('usuario');
    return user.nombre + ' ' + user.apellido;
}
export const setUserImg = (img) =>{
    setItemStorage('userImg',img);
}
export const getToken = () =>{
    return getItemStorage('token');
}
export const LogOut = () =>{
    removeItemStorage('usuario');
    removeItemStorage('token');
    removeItemStorage('userImg');
    window.location.href = "/home";
}