
const API_URL = "https://bateria.climagym.com.ar/api/";
const PostRequest= (url, formData) =>{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "POST", url, false ); // false for synchronous request
    xmlHttp.send( formData );
    
    return JSON.parse(xmlHttp.responseText);
}
const GetRequest= (url) =>{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", url, false ); // false for synchronous request
    xmlHttp.send( null );
    return JSON.parse(xmlHttp.responseText);
}
export const CrearActividad = (formData) =>{
    try{
        return PostRequest(API_URL+"postActividad.php", formData);
    }catch(e){
        return []
    }

}
export const DeleteActividad = (id) =>{
    try{
        return GetRequest(API_URL+"deleteActividad.php?id="+id);
    }catch(e){
        return []
    }

}

export const putActividad = (formData) =>{
    try{
        return PostRequest(API_URL+"putActividad.php", formData);
    }catch(e){
        return []
    }

}
export const Inscribir = (formData) =>{
    try{
        return PostRequest(API_URL+"inscribirSocio.php", formData);
    }catch(e){
        return []
    }

}
