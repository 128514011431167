import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBarOld';
import { GetGrilla } from '../../utils/GrillaHelper'
class GrillaMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {show:false, 
            lunes: [],
            martes: [],
            miercoles: [],
            jueves: [],
            viernes: [],
            sabado: [],
            domingo: []}
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
        let lunes = GetGrilla(1)
        let martes = GetGrilla(2);
        let miercoles = GetGrilla(3);
        let jueves = GetGrilla(4);
        let viernes = GetGrilla(5);
        let sabado = GetGrilla(6);
        let domingo =[];
        
        this.setState({lunes, martes, miercoles, jueves, viernes, sabado, domingo, show:true})
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar goTo={this.goTo}/>
            <Page
                currentItem={currentItem}
                goTo={this.goTo}
                lunes={this.state.lunes}
                martes={this.state.martes}
                miercoles={this.state.miercoles}
                jueves={this.state.jueves}
                viernes={this.state.viernes}
                sabado={this.state.sabado}
                domingo={this.state.domingo}

            />
            </React.Fragment>
        );
    }
}


export default GrillaMobile;