import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';

import Link from '@material-ui/core/Link';
import MailIcon from '@material-ui/icons/Mail';

import {
    UsuarioLogueado,LogOut
  } from '../../utils/UserManager';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    marginLeft:100,
    borderRadius: '.5rem',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'rgb(255, 255, 255)',
    color:'black'
  },
  appBarShift: {
    marginTop: '10px',
    marginRight:10,
    marginLeft: drawerWidth + 20,
    width: `calc(100% - ${drawerWidth + 20}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    paddingTop: 10,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
     overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));




export default function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [disableDrawer, setDisableDrawer] = React.useState(props.disableDrawer);

  const userLogueado = UsuarioLogueado();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(setAnchorEl);
  const handleDrawerOpen = () => {
    if(userLogueado && !disableDrawer)
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(!openMenu);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

const menuItems = () =>{
    if(UsuarioLogueado())
    {
      return (<React.Fragment>
         <MenuItem onClick={() =>{props.goTo("/socios")}}>Socios</MenuItem>
         <MenuItem onClick={() =>{props.goTo("/gastos")}}>Gastos</MenuItem>
         <MenuItem onClick={() =>{props.goTo("/reporte")}}>Reporte</MenuItem>

        <MenuItem onClick={LogOut}>Cerrar sesion</MenuItem>    
      </React.Fragment> );
    }else {
      return (<React.Fragment>
        <MenuItem onClick={() =>{props.goTo("/login")}}>Iniciar sesion</MenuItem>
      </React.Fragment> );
    }
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      style={{marginTop: 50}}
      onClose={handleMenuClose}
      open={openMenu}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {menuItems()}
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Typography variant="h6" noWrap>
          {!open && <img src="/static/logo.png" style={{maxHeight: '50px', width: 'auto'}} />  } 
          </Typography>
          <div className={classes.grow} />

          <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenuClose}
            >

            </IconButton>
            <Link href="/ingresos" style={{color:'#8f2754', fontSize:20}}>Ingresos </Link>
            <Link href="/morosos" style={{color:'#8f2754', fontSize:20, marginLeft:10}}>Morosos </Link>
            <Link href="/actividades" style={{color:'#8f2754', fontSize:20, marginLeft:10}}>Actividades </Link>

            <Link href="/socios" style={{color:'#8f2754', fontSize:20, marginLeft:10}}>Socios </Link>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={1}
              aria-haspopup="true"
              color="inherit"     
              onClick={handleMenuClose}
            >
              <MoreIcon />
            </IconButton>
          </div>
          
        </Toolbar>
        
      </AppBar>
      {renderMenu}

    </div>
  );
}
