export const GetDia = (id) =>{
    
    GetProximaClase([2,4,5]);
    if(id == 1) return "Lunes";
    if(id == 2) return "Martes";
    if(id == 3) return "Miercoles";
    if(id == 4) return "Jueves";
    if(id == 5) return "Viernes";
    if(id == 6) return "Sabado";
    if(id == 7) return "Domingo";

    return "Lunes";

}
export const FormatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
 
export const GetDiaJs = (id) =>{
    
    GetProximaClase([2,4,5]);
    if(id == 0) return "Domingo";
    if(id == 1) return "Lunes";
    if(id == 2) return "Martes";
    if(id == 3) return "Miercoles";
    if(id == 4) return "Jueves";
    if(id == 5) return "Viernes";
    if(id == 6) return "Sabado";

    return "Lunes";

}
function getNextDayOfWeek(date, dayOfWeek) {
    // Code to check that date and dayOfWeek are valid left as an exercise ;)

    var resultDate = new Date(date.getTime());

    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);

    return resultDate;
}
export const GetProximaClase = (dias) =>{
    var proximasClases = []

    dias.map(d =>{
       var hoy = new Date();
       var fecha = getNextDayOfWeek(hoy, d);
       proximasClases.push(fecha);
    })
    proximasClases = proximasClases.sort((a, b) => a - b);
    return proximasClases;

}