import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '../common/cardHorarios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import './style.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
    GetCategorias,
    GetActividades
} from '../../utils/CategoriasManager';

import {
    GetSocioDNI
} from '../../utils/SocioManager';
import {
    GetDia
} from '../../utils/DiasHelper';

function Page(props) {
    const {
        goTo,
        currentItem,
    } = props;
    const [mostrarSocio, setMostrarSocio] = React.useState(false);
    const [dni, setDni] = React.useState(null);
    const [socio, setSocio] = React.useState(null);
    const [socioInexistente, setSocioInexistente] = React.useState(false);

    const dniSocioUri = window.location.href.split('/')[4];

    const [actividades, setActividades] = React.useState([]);
    const CargarDatos=()=>{
        var dniUser = document.getElementById("dni").value;
        
        if(dniUser){
        setDni(dniUser);
        var socio = GetSocioDNI(dniUser);
        setSocio(socio); 
        if(socio)
        setActividades(socio.actividades); 
        else
        setSocioInexistente(true);

        setMostrarSocio(true); 
        }else{
            alert('Por favor: ingresa tu dni')
        }
    }
    return (
        <Fragment>
            <CssBaseline />


            <div style={{flexBasis: '100%'}}></div> 
            {/* aca sugeridos */}

            <div className="details-page" style={{display: 'flex',flexWrap: 'wrap', marginTop:100, background:'#fff'}}>
            <Typography variant="h4" component="h4">Tus actividades</Typography><br />
            
            
            {/* aca input dni */}
            <div style={{flexBasis: '100%'}}></div> 
            {!mostrarSocio &&  <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <TextField label="Ingresá tu DNI" id="dni"  />
             <Button variant="contained" size="large" color="primary" onClick={CargarDatos} style={{ background:'#7c3259', marginLeft: 10}}>
               Continuar
                </Button>
            </div> }

            <div style={{flexBasis: '100%'}}></div> 
            {/* aca actividades */}
            {mostrarSocio &&  <Fragment>
                {actividades.map(c =>{
                return <Card deshabilitarInscripcion={true} asistencia={c.asistencia} idTurno={c.idTurno} categoria={c.categoria} horarios={c.horarios} id={c.id} onClickButton={props.goTo} descripcion={c.descripcion} description={c.descripcion} subheader={c.descripcion}masInfo={c.masInfo} image={c.imagen} />
            }
                )}
            {!socioInexistente && actividades.length == 0 && <div style={{height:'200px'}} component="h2">
          Actualmente no estas anotado en ninguna actividad.
          </div>}
          {socioInexistente && <div style={{height:'200px'}} component="h2">
          ¡No estas registrado en sistema, solicita el registro en el gimnasio!
          </div>}
            </Fragment>}
            <div style={{flexBasis: '100%'}}></div> 

           
            </div>
        </Fragment>
    );
}

export default Page;