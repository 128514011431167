import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBar';
import {
    GetPagos, PostPago,DeletePago
} from '../../utils/PagosManager';
import {
    UsuarioLogueado
} from '../../utils/UserManager';
import {
    GetCategorias
} from '../../utils/CategoriasManager';
import {
    parse_query_string
} from '../../utils/uri';

class Ingresos extends Component {
    constructor(props) {
        super(props);
        this.state = {pagos :[],show:false,categoriasLookUp:[],idSocio:null}
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
    if(!UsuarioLogueado()){
        window.location.href = "/login";
    } else
    {
     var categorias = GetCategorias();
     
     categorias.push({id:1001, nombre:'Estetica'});
     categorias.push({id:1002, nombre:'Otros'});

     var categoriasLookUp = categorias.reduce(function(acc, cur, i) {
        acc[cur.id] = cur.nombre;
        return acc;
        }, {});
      let pagos = GetPagos();
     this.setState({pagos, show:true,categoriasLookUp}) 
    }
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar DrawerOpenedDefault={true} goTo={this.goTo} path='/ingresos' />
            {this.state.show &&<Page
                currentItem={currentItem}
                pagos={this.state.pagos}
                onCreate={PostPago}
                categoriasLookUp={this.state.categoriasLookUp}
                onDelete={DeletePago}
                goTo={this.goTo}
                idSocio={this.state.idSocio}
            />}
            {!this.state.show && <span>Cargando socios...</span>}
            </React.Fragment>
        );
    }
}


export default Ingresos;