import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from 'material-table';
import {FormatDate} from '../../utils/DiasHelper';
function Page(props) {
    const {
        // goTo,
        // currentItem,
    } = props;
    console.log(props.categoriasLookUp)
    const [state, setState] = React.useState({
      columns: [
        { title: 'Actividad', field: 'idCategoria',lookup: props.categoriasLookUp},
        
        { title: 'Periodo cobrado', field: 'periodo', type:'date' },
        { title: 'Monto', field: 'monto', type: 'numeric' },
        {
          title: 'Descripcion',
          field: 'descripcion'
        },
        { title: 'Medio de pago', 
          field: 'formaPago', 
          lookup: { 'Efectivo':'Efectivo', 'MercadoPago' :'MercadoPago' ,'Transferencia' :'Transferencia' }
        },
        { title: 'Fecha de pago', field: 'fechaPago', editable: 'never'  },
        { title: 'Id', field: 'id', editable: 'never'  }

      ],
      data: props.pagos,
    });
  
  
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{ marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>

            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <MaterialTable
      title="Ingresos"
      columns={state.columns}
      data={state.data}
      style={{width:'100%'}}
      localization={{ body: { editRow: { deleteText: '¿Estás seguro de eliminar al pago?' } } }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve,reject) => {
            setTimeout(async () => {
                            
              // if(!newData.name){
              //   reject();
              //   alert('flaco la puta madre, pone un nombre');
              //   return;
              // }
              newData.periodo = FormatDate(newData.periodo);
              var dbData = await props.onCreate(newData);

              resolve();
              setState((prevState) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            }, 600);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              props.onDelete(oldData.id);
              resolve();
              setState((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
            </div>
        </Fragment>
    );
}

export default Page;