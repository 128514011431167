import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBar';

let seteArcampos = () =>{
       
}
let setSetearCampos = (func) =>{
    seteArcampos = func;
}

class EditarActividad extends Component {
    constructor(props) {
        super(props);

        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
        seteArcampos();
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar goTo={this.goTo}/>
            <Page
                currentItem={currentItem}
                setSetearCampos={setSetearCampos}
                goTo={this.goTo}
            />
            </React.Fragment>
        );
    }
}


export default EditarActividad;