import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from 'material-table';
import {FormatDate} from '../../utils/DiasHelper';
function Row(array, categorias, titulo){
    var object = {id:titulo};
    console.log(array,'array')
    categorias.map(c => {
        object[c.id] = array.filter(e => e.categoria == c.id)[0]?.monto ?? 0;
    });
    var suma = 0;
    array.map(e => {suma = suma + parseFloat(e.monto)});
    object["suma"] = suma;
    return object;

}
function RowTotales(ingreso, egreso, categorias){
    var object = {id:'Totales'};
    categorias.map(c => {
        object[c.id] = (ingreso.filter(e => e.categoria == c.id)[0]?.monto ?? 0) - (egreso.filter(e => e.categoria == c.id)[0]?.monto ?? 0);
    });
    var sumaIngresos = 0;
    ingreso.map(e => {sumaIngresos = sumaIngresos + parseFloat(e.monto)});
    var sumaEgresos = 0;
    egreso.map(e => {sumaEgresos = sumaEgresos + parseFloat(e.monto)});
    object["suma"] = sumaIngresos - sumaEgresos;
    return object;

}
function Page(props) {
    const {
        // goTo,
        // currentItem,
    } = props;
    var reporte = props.GetReporte(props.mes,props.anio);
    var columnas = [{title:"-",field:"id"}].concat(props.categorias.map(c => ({title: c.nombre, field:c.id}))).concat([{title:"Sumatoria General",field:"suma"}])
    var filas = [];
    filas.push(Row(reporte.ingresos, props.categorias,'Ingresos'));
    filas.push(Row(reporte.egresos, props.categorias, 'Egresos'));
    filas.push(RowTotales(reporte.ingresos, reporte.egresos, props.categorias));
    const [state, setState] = React.useState({
      columns: columnas,
      data: filas,
    });
  
  
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{ marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>

            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <MaterialTable
      title={"Reporte de ingresos mensual (mes "+props.mes+")"}
      columns={state.columns}
      data={state.data}
      style={{width:'100%'}}
    />
            </div>
        </Fragment>
    );
}

export default Page;