import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from 'material-table';
import {
    GetActividad,GetCategorias,GetInscriptos
} from '../../utils/CategoriasManager';
import ListItems from '../common/ListItem';

import Button from '@material-ui/core/Button';
import {
  GetDia
} from '../../utils/DiasHelper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {DeleteTurno
} from '../../utils/SocioManager';

function Page(props) {
    const {
        // goTo,
        // currentItem,
    } = props;
    const getHorarios=(horarios)=>{
      var stringHorario = "";
      horarios.map(h =>{
          stringHorario =stringHorario + GetDia(h.dia) +" de "+h.horaDesde+ " a "+h.horaHasta+". "
      })
      return stringHorario;
  }
  
  const idActividad = window.location.href.split('/')[4];
    const actividad = GetActividad(idActividad);
    const inscriptos = GetInscriptos(idActividad);
    
    console.log(inscriptos)
    const categorias = GetCategorias();
    const getCategoria = id =>{
        let categoria = categorias.filter(c => c.id == id)[0];
        return categoria?.nombre;
      };

    const removeItem = id =>{
       
      };
    const items = inscriptos.map(i  =>{
        return {
          nombre:i.nombre,
          apellido: i.apellido,
          dni: i.dni,
          asistencia: i.asistencia ?? "Siempre",
          delete:<DeleteIcon style={{cursor: 'pointer'}} onClick={() =>  {DeleteTurno(i.idTurno);
             alert('Turno eliminado correctamente');
             window.location.reload();
            }} />,
        }})
    const [state, setState] = React.useState({
          columns: [
            { title: 'Nombre', field: 'nombre', editable: 'never' },  
            { title: 'Apellido', field: 'apellido', editable: 'never' },  
            { title: 'Dni', field: 'dni', editable: 'never' },  
            { title: 'Dia', field: 'asistencia', editable: 'never' },  
            { title: 'Cancelar turno', field: 'delete', editable: 'never' },  


          ],
          data: items,
        });
      
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{marginLeft:'250px', marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div>{getCategoria(actividad.categoria)}</div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div>{"Cupo: "+actividad.cupo}</div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div>{"Descripcion: "+actividad.descripcion}</div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div>{"Horarios: "+getHorarios(actividad.horarios)}</div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 
            {/* <ListItems items={items} /> */}
            <MaterialTable
                title="Inscriptos"
                columns={state.columns}
                data={state.data}
                style={{width:'100%'}}
            />
            </div>

        </Fragment>
    );
}

export default Page;