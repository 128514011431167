import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBar';
import {
    GetGastos, PostPago,DeletePago
} from '../../utils/PagosManager';
import {
    UsuarioLogueado
} from '../../utils/UserManager';
import {
    GetCategorias
} from '../../utils/CategoriasManager';
import {
    parse_query_string
} from '../../utils/uri';

class Gastos extends Component {
    constructor(props) {
        super(props);
        this.state = {pagos :[],show:false,categoriasLookUp:[],idSocio:null,needsPassword:true}
        this.goTo = this.goTo.bind(this);
        this.validarPassword = this.validarPassword.bind(this);
    }

    componentDidMount() {
    if(!UsuarioLogueado()){
        window.location.href = "/login";
    } else
    {
     var categorias = GetCategorias();
          
     categorias.push({id:1001, nombre:'Estetica'});
     categorias.push({id:1002, nombre:'Otros'});
     
     var categoriasLookUp = categorias.reduce(function(acc, cur, i) {
        acc[cur.id] = cur.nombre;
        return acc;
        }, {});

      this.setState({categoriasLookUp}) 
    }
    }

    goTo(path) {
        this.props.history.push(path);
    }
    validarPassword(){
        var pass = document.getElementById("passwordKPO").value;
        if(pass == "brancalamanca"){
            let pagos = GetGastos();
            this.setState({show:true,pagos}) 
        }else{
            alert('Contraseña incorrecta');
        }
    }
    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar DrawerOpenedDefault={true} goTo={this.goTo} path='/ingresos' />
            {!this.state.show && <div style={{marginTop:100}}>
            <center>
            <input type="password" id="passwordKPO" placeholder="Ingresar contraseña"/>
            <input type="button" onClick={this.validarPassword} value="ingresar"/>
            </center>

            </div>}
            {this.state.show &&<Page
                currentItem={currentItem}
                pagos={this.state.pagos}
                onCreate={PostPago}
                categoriasLookUp={this.state.categoriasLookUp}
                onDelete={DeletePago}
                goTo={this.goTo}
                idSocio={this.state.idSocio}
            />}
            </React.Fragment>
        );
    }
}


export default Gastos;