const API_URL = "https://bateria.climagym.com.ar/api/";
const GetRequest= (url) =>{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", url, false ); // false for synchronous request
    xmlHttp.send( null );
    return JSON.parse(xmlHttp.responseText);
}
export const GetCategorias = (id) =>{
    try{
        var categorias =[];
        if(sessionStorage['categorias']){
         categorias = JSON.parse(sessionStorage['categorias']);
        }
         else{
         categorias = GetRequest(API_URL+"categorias.php");
         sessionStorage.setItem('categorias', JSON.stringify(categorias));
        }
         if(id) return categorias.filter(c => c.id == id);

         return categorias;

    }catch(e){
        return []
    }

}

export const GetActividades = (idCategoria) =>{
    try{
        return GetRequest(API_URL+"actividades.php?categoria="+idCategoria);
    }catch(e){
        return []
    }

}
export const GetActividade = () =>{
    try{
        return GetRequest(API_URL+"actividades.php");
    }catch(e){
        return []
    }

}
export const GetActividad = (idActividad) =>{
    try{
        return GetRequest(API_URL+"actividades.php?id="+idActividad)[0];
    }catch(e){
        return null
    }

}

export const GetInscriptos = (idActividad) =>{
    try{
        return GetRequest(API_URL+"getInscriptos.php?id="+idActividad);
    }catch(e){
        return null
    }

}