import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '../common/card';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import './style.css';
import Button from '@material-ui/core/Button';
import BackgroundSlider from 'react-background-slider'
import {
    GetCategorias
} from '../../utils/CategoriasManager';
function Page(props) {
    const {
        goTo,
        currentItem,
    } = props;
    const categorias = GetCategorias();
    const VerActividades = () =>{
        document.getElementById('actividades').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }

    return (
        <Fragment>
            <CssBaseline />


            <div className="details-page" style={{display: 'flex',flexWrap: 'wrap', width:'100%'}}>
                
            <BackgroundSlider
            images={["/static/TAEKWONDO.jpeg"
            , "/static/PILATES.jpeg",
            "/static/ACROBACIA.jpeg"
            ,"/static/CROSS%202.jpeg"
            ,"/static/PARKOUR.jpeg"
            ,"/static/YOGA.jpeg"
            ,"/static/FLEXIBILIDAD.jpeg"
            ,"/static/FUNCIONAL.jpeg"
            ,"/static/ZUMBA.jpeg"
            ]}
            duration={5} transition={2}
            style={{height:100}} />
{/* #853daa */}
            </div>
            <div style={{ background:'rgb(99 99 99 / 32%)', paddingTop:'150px', marginTop:'-150px'}}>
            {/* aca logo */}
            <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>

            <img src='/static/logo.png' style={{maxWidth:'40%'}} /> 
            </div> 
            <div style={{flexBasis: '100%'}}></div> 

            {/* aca buscador */}
            <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <Button variant="contained" size="large" onClick={VerActividades} color="primary" style={{ background:'#7c3259'}}>
                Inscribirme
                </Button>
                <Button variant="contained" size="large" onClick={() => {goTo("/mis-actividades")}} color="primary" style={{ background:'#7c3259', marginLeft: 10}}>
                Ver mis actividades
                </Button>
            </div> 
            <div style={{flexBasis: '100%'}}></div> 
            </div>
            {/* aca sugeridos */}

            <div className="details-page" style={{display: 'flex',flexWrap: 'wrap', background:'#fff'}}>
            <Typography variant="h4" component="h4" id="actividades">Nuestras actividades</Typography><br />
            <div style={{flexBasis: '100%'}}></div> 
            {categorias.map(c =>{
                return <Card title={c.nombre} id={c.id} onClickButton={goTo}  detail={c.descripcion} description={c.descripcion} subheader={c.descripcion}masInfo={c.masInfo} image={c.imagen} />
            }
                )}
           
            </div>
        </Fragment>
    );
}

export default Page;