import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '../common/cardHorarios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItems from '../common/ListItem';
import {
  GetDia
} from '../../utils/DiasHelper';
import MenuItem from '@material-ui/core/MenuItem';
import BackgroundSlider from 'react-background-slider'
import {
    GetActividades,GetCategorias
} from '../../utils/CategoriasManager';

import TextField from '@material-ui/core/TextField';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function Page(props) {
    const {
        v,
        currentItem,
    } = props;
    const useStyles = makeStyles((theme) => ({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
        formControlSelect: {
            margin: theme.spacing(1),
            minWidth: 255,
          },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
      }));
    const id = window.location.href.split('/')[4];
    const [open, setOpen] = React.useState(false);
    const [categoria, setCategoria] = React.useState(GetCategorias(id)[0]);
    const [actividades, setActividades] = React.useState(GetActividades(id));
    const [actividadesOriginales, setActividadesOriginales] = React.useState(actividades);






    const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const horaToInt = (hora) => {
    return parseInt(hora.replace(":",""));
  };
  const filtrarResultados =()=>  {
    var dia = document.getElementsByName("demo-simple-select-outlined")[0].value;
    var horaDesde = document.getElementById("desde").value;
    var horaHasta = document.getElementById("hasta").value;
    var filtrados = actividadesOriginales.filter(a => 
        a.horarios.some(h =>
            (!!!dia || h.dia == dia)
            &&
            (!!!horaDesde ||horaToInt(h.horaDesde) >= horaToInt(horaDesde))
            &&
            (!!!horaHasta || horaToInt(h.horaHasta) <= horaToInt(horaHasta))
            )
        );
        
    setActividades(filtrados);
    handleClose();
  };
    return (
        <Fragment>
            <CssBaseline />


            <div className="details-page" style={{display: 'flex',flexWrap: 'wrap', width:'100%'}}>
                
            <BackgroundSlider
            images={[categoria.imagen]}
            duration={100000000000000000000000} transition={2}
            style={{height:100}} />
{/* #853daa */}
            </div>
            <div style={{ background:'rgb(99 99 99 / 32%)', paddingTop:'150px', marginTop:'-150px'}}>
            {/* aca logo */}
            <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <div style={{ padding: 10, paddingBottom: 5, background: 'rgb(124 50 89 / 65%)'}}>
                <Typography gutterBottom variant="h3" style={{color:'#fff',textShadow: 'rgb(5 5 5) 1px 2px 3px'}} component="h2">
                 {categoria.nombre}
                </Typography>
            </div>

            </div> 
            <div style={{flexBasis: '100%'}}></div> 

            {/* aca buscador */}
            <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <Button variant="contained" size="large" color="primary" onClick={() =>{window.scrollTo(0, 300)}} style={{ background:'#7c3259'}}>
               Ver clases y horarios
                </Button>
            </div> 
            <div style={{flexBasis: '100%'}}></div> 
            </div>
            {/* aca sugeridos */}

            <div className="details-page" style={{display: 'flex',flexWrap: 'wrap', background:'#fff'}}>
            {actividades.length != 0 &&<Typography variant="h4" component="h4">Horarios de esta actividad</Typography>}
            <Button variant="contained" size="large" color="primary" onClick={handleClickOpen} style={{ background:'#7c3259'}}>
              Filtrar horarios
                </Button>
            <div style={{flexBasis: '100%'}}></div> 
            {actividades.map(c =>{
                return <Card horarios={c.horarios} categoria={c.categoria} id={c.id} onClickButton={props.goTo} descripcion={c.descripcion} description={c.descripcion} subheader={c.descripcion}masInfo={c.masInfo} image={c.imagen} />
            }
                )}
            {actividades.length == 0 && <div style={{height:'200px'}} component="h2">
          Actualmente no tenemos clases disponibles de esta actividad.
          </div>}
          <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Filtrar horarios"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <FormControl variant="outlined"  className={classes.formControlSelect}>
        <InputLabel id="demo-simple-select-label">Dia</InputLabel>  
        <Select
       labelId="demo-simple-select-outlined-label"
       id="demo-simple-select-outlined"
       name="demo-simple-select-outlined"

        >
          <MenuItem value={1}>Lunes</MenuItem>
          <MenuItem value={2}>Martes</MenuItem>
          <MenuItem value={3}>Miercoles</MenuItem>
          <MenuItem value={4}>Jueves</MenuItem>
          <MenuItem value={5}>Viernes</MenuItem>
          <MenuItem value={6}>Sabado</MenuItem>
          <MenuItem value={7}>Domingo</MenuItem>
        </Select>
        
      </FormControl>
      <FormControl variant="outlined"  className={classes.formControl}>
      
      <TextField id="desde" label="Desde"  variant="outlined" type="time"  placeholder="20:00" />

</FormControl>
<FormControl variant="outlined"  className={classes.formControl}>
     
      <TextField id="hasta" label="Hasta" variant="outlined"  type="time"  placeholder="22:00" />
</FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={filtrarResultados} color="primary">
            Filtrar
          </Button>
        </DialogActions>
      </Dialog>
            </div>
        </Fragment>
    );
}

export default Page;