import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBar';
import {
    GetSociosMorosos
} from '../../utils/SocioManager';
import {
    UsuarioLogueado
} from '../../utils/UserManager';
import {
    GetCategorias
} from '../../utils/CategoriasManager';
import {
    parse_query_string
} from '../../utils/uri';

class Morosos extends Component {
    constructor(props) {
        super(props);
        this.state = {pagos :[],show:false,categoriasLookUp:[],idSocio:null}
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
    if(!UsuarioLogueado()){
        window.location.href = "/login";
    } else
    {
      let sociosMorosos = GetSociosMorosos();
     this.setState({sociosMorosos, show:true}) 
    }
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar DrawerOpenedDefault={true} goTo={this.goTo} path='/ingresos' />
            {this.state.show &&<Page
                currentItem={currentItem}
                sociosMorosos={this.state.sociosMorosos}

                goTo={this.goTo}
                idSocio={this.state.idSocio}
            />}
            {!this.state.show && <span>Cargando morosos...</span>}
            </React.Fragment>
        );
    }
}


export default Morosos;