import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from 'material-table';
import {FormatDate} from '../../utils/DiasHelper';
function Page(props) {
    const {
        // goTo,
        // currentItem,
    } = props;
    const [state, setState] = React.useState({
      columns: [
        { title: 'Id', field: 'id',lookup: props.categoriasLookUp},
        
        { title: 'Nombre', field: 'nombre', type:'date' },
        { title: 'Apellido', field: 'apellido', type: 'numeric' },
        {
          title: 'DNI',
          field: 'dni'
        },
        { title: 'Notas', 
          field: 'notas'
        },
        { title: 'Celular', field: 'celular' },
        { title: 'Email', field: 'email' },
        { title: 'Fecha ultimo pago', field: 'UltimoPeriodoPago' }
      ],
      data: props.sociosMorosos,
    });
  
  
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{ marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>

            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <MaterialTable
      title="Socios Morosos"
      columns={state.columns}
      data={state.data}
      style={{width:'100%'}}
    
    />
            </div>
        </Fragment>
    );
}

export default Page;