export const setItemStorage = (key,item) =>{
    let miStorage = window.localStorage;
    miStorage.setItem(key,JSON.stringify(item));
}

export const getItemStorage = (data) =>{
    let miStorage = window.localStorage;
    return JSON.parse(miStorage.getItem(data));
}

export const removeItemStorage= (data) =>{
    let miStorage = window.localStorage;
    miStorage.removeItem(data);
}

export const clearStorage= () =>{
    let miStorage = window.localStorage;
    miStorage.clear();
}
