import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    fontSize: '20px',
    fontWeight: 'bold',
    width: '100%',
    color:'rgb(124, 50, 89)',
    height: '100%',
    zIndex: 9999,
    background: 'url("/static/loader1.gif") 50% 50% no-repeat rgb(249,249,249,0.67)',
    backgroundSize: '300px 300px'
  },
  text: {
     marginTop:'190px'
    },
}));

export default function CircularIndeterminate(props) {
  const classes = useStyles();

  return (
    <div>
    {props.showLoader && <div className={classes.root}>
    <div  className={classes.text}>
    <center>Cargando...</center>
    </div>
    </div>}
    </div>
  );
}
