import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBar';
import {
    GetReporte
} from '../../utils/PagosManager';
import {
    UsuarioLogueado
} from '../../utils/UserManager';
import {
    GetCategorias
} from '../../utils/CategoriasManager';
import {
    parse_query_string
} from '../../utils/uri';

class Reporte extends Component {
    constructor(props) {
        super(props);
        this.state = {pagos :[],show:false,categoriasLookUp:[],idSocio:null,needsPassword:true}
        this.goTo = this.goTo.bind(this);
        this.validarPassword = this.validarPassword.bind(this);
    }

    componentDidMount() {
    if(!UsuarioLogueado()){
        window.location.href = "/login";
    } else
    {
     var categorias = GetCategorias();
          
     categorias.push({id:1001, nombre:'Estetica'});
     categorias.push({id:1002, nombre:'Otros'});
     
     var categoriasLookUp = categorias.reduce(function(acc, cur, i) {
        acc[cur.id] = cur.nombre;
        return acc;
        }, {});

      this.setState({categorias}) 
    }
    }

    goTo(path) {
        this.props.history.push(path);
    }
    getYearsBetween2021AndCurrent() {
        const currentYear = new Date().getFullYear();
        const startYear = 2021;
      
        const years = [];
        for (let year = currentYear; year >= startYear; year--) {
            years.push(year);
        }
      
        return years;
      }
    validarPassword(){
        var pass = document.getElementById("passwordKPO").value;
        var e = document.getElementById("anio");
        var anio = e.value;
        var e = document.getElementById("mes");
        var mes = e.value;
        if(pass == "brancalamanca"){
            this.setState({show:true, mes, anio}) 
        }else{
            alert('Contraseña incorrecta');
        }
    }
    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar DrawerOpenedDefault={true} goTo={this.goTo} path='/ingresos' />
            {!this.state.show && <div style={{marginTop:100}}>
            <center>
            <select name="anio" id="anio">
                {
                    this.getYearsBetween2021AndCurrent().map(y => {
                       return (<option value={y}>{y}</option>)
                    })
                }
            </select>
            <select name="mes" id="mes">
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
            </select><br /><br />
            <input type="password" id="passwordKPO" placeholder="Ingresar contraseña"/><br />
            <input type="button" onClick={this.validarPassword} value="ingresar"/>
            </center>

            </div>}
            {this.state.show &&<Page
                currentItem={currentItem}
                GetReporte={GetReporte}
                mes={this.state.mes}
                anio={this.state.anio}
                categorias={this.state.categorias}
                goTo={this.goTo}
            />}
            </React.Fragment>
        );
    }
}


export default Reporte;