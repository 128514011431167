import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBar';
import {
    setItemStorage, getItemStorage,
} from '../../utils/LocalStorageManager';
import {
    GetSocios, PostSocio,PutSocio,DeleteSocio
} from '../../utils/SocioManager';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {socios :[],show:false}
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
     this.setState({show:true})
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const {
        } = this.props;

        return (
            <React.Fragment>
            <AppBar DrawerOpenedDefault={false} goTo={this.goTo}  />
            {this.state.show &&<Page

                goTo={this.goTo}
            />}
            {!this.state.show && <span>Cargando login...</span>}
            </React.Fragment>
        );
    }
}


export default Login;