import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CardMedia from '@material-ui/core/CardMedia';
import Loader from '../common/Loader'

import {
    GetDia,GetDiaJs
} from '../../utils/DiasHelper';
import {DeleteTurno
} from '../../utils/SocioManager';

import {
  GetCategorias
} from '../../utils/CategoriasManager';
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    width: 345,
    padding:10,
    margin:10
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);
  const [turnoCancelado, setTurnoCancelado] = React.useState(false);

  const categoria = props.categoria ?  GetCategorias(props.categoria)[0] : null;
  const GetDiaAsistencia=(date)=>{ 
   var fecha = new Date(date);
   var dia = GetDiaJs(fecha.getDay())
   var hora = props.horarios.filter(p => GetDia(p.dia) == dia)[0];
   return dia+" "+fecha.getDate()+ " de "+hora.horaDesde+" a "+hora.horaHasta;

}
  return (
    <Card className={classes.root}>
      <CardActionArea>
      {categoria &&<CardMedia
          className={classes.media}
          image={categoria.imagen}
          title={categoria.nombre}
        />}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          {categoria && <div style={{minHeight:0}}>{categoria.nombre}</div> }
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {props.descripcion && <div style={{minHeight:0}}>{props.descripcion}</div> }
          {props.horarios && <div style={{minHeight:0}}>Dias y horarios:</div> }
          {!props.asistencia && props.horarios.map(h =>{
                return(<Chip
                icon={<AccessTimeIcon />}
                label={GetDia(h.dia)+" "+h.horaDesde+" a "+h.horaHasta }
                color="primary"
                deleteIcon={<DoneIcon />}
              />)
            }
                )}
          {props.asistencia && 
          <Chip
          icon={<AccessTimeIcon />}
          label={GetDiaAsistencia(props.asistencia)}
          color="primary"
          deleteIcon={<DoneIcon />} />
          }

                
           

          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{width:'100%'}}>
      {!props.deshabilitarInscripcion &&<center> <Button variant="contained" onClick={() =>{ setShowLoader(true); 
          setTimeout(function(){ 
            window.location.href = "/inscripcion/"+props.id;
        }, 200);
          }} size="large" style={{width:'100%'}} color="secondary" style={{ background:'#7c3259'}}>
          Inscribirme
        </Button>   </center>
        }
        {props.deshabilitarInscripcion &&<center> <Button variant="contained" onClick={() =>{ 
          if(!turnoCancelado){ 
          setShowLoader(true); 
          setTimeout(function(){ 
            DeleteTurno(props.idTurno);
            setShowLoader(false);
            setTurnoCancelado(true);
        }, 100);
      }
          }} size="large" style={{width:'100%'}} color="secondary" style={{ background:'#7c3259'}}>
          {!turnoCancelado ? 'Cancelar turno': 'El turno fue cancelado.'}
        </Button>   </center>
        }
      </CardActions>
      <Loader showLoader={showLoader} />
    </Card>
  );
}
