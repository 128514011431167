const API_URL = "https://bateria.climagym.com.ar/api/";
const GetRequest= (url) =>{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", url, false ); // false for synchronous request
    xmlHttp.send( null );
    return JSON.parse(xmlHttp.responseText);
}

export const GetGrilla = (dia) =>{
    try{

         return GetRequest(API_URL+"grilla.php?dia="+dia);;

    }catch(e){
        return []
    }

}
 