import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Loader from '../common/Loader'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    width: 345,
    padding:10,
    margin:10
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.image}
          title={props.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          <div style={{minHeight:80}}>{props.description}</div>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button variant="contained" onClick={() =>{ setShowLoader(true); 
          setTimeout(function(){ 
            window.location.href = "/categoria/"+props.id;
        }, 220);
          }} size="small" color="secondary" style={{ background:'#7c3259'}}>
          Inscribirme
        </Button>
        <Button variant="contained" onClick={ () =>  alert(props.masInfo)}  size="small" color="primary" style={{ background:'#7c3259'}}>
          mas informacion
        </Button>
      </CardActions>
      <Loader showLoader={showLoader} />
    </Card>
  );
}
