import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
    GetActividad,GetCategorias
} from '../../utils/CategoriasManager';
import {
    GetSocioDNI
} from '../../utils/SocioManager';
import {
    GetDia,GetDiaJs,GetProximaClase
} from '../../utils/DiasHelper';
import {
    Inscribir
} from '../../utils/ActividadesHelper';

function Page(props) {
    const {
        goTo,
        currentItem,
    } = props;
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
            
            return [day, month, year].join('/');
    }
     
    const [mostrarSocio, setMostrarSocio] = React.useState(false);
    const [dni, setDni] = React.useState(null);
    const [socio, setSocio] = React.useState(null);
    const [inscripto, setInscripto] = React.useState(false);
    const [diasIncripcion, setDiasInscripcion] = React.useState([]);


    const idActividad = window.location.href.split('/')[4];
    const actividad = GetActividad(idActividad);
    const categoria = GetCategorias(actividad.categoria)[0];

    const dias = actividad.horarios.map(a => parseInt(a.dia));
    var proximasClases = GetProximaClase(dias);
    console.log(proximasClases);
    const changeDni=({target})=>{
        setDni(target.value);
    }
    const getHorario=(h)=>{ 
        return GetDia(h.dia) +" de "+h.horaDesde+ " a "+h.horaHasta;

    }
    function getCheckedBoxes(chkboxName) {
        var checkboxes = document.getElementsByName(chkboxName);
        var checkboxesChecked = [];
        // loop over them all
        for (var i=0; i<checkboxes.length; i++) {
           // And stick the checked ones onto an array...
           if (checkboxes[i].checked) {
              checkboxesChecked.push(checkboxes[i].value);
           }
        }
        // Return the array if it is non-empty, or null
        return checkboxesChecked.length > 0 ? checkboxesChecked : null;
      }
    const  ConfirmarInscripcionTest=()=>{
        var dates = getCheckedBoxes("checkDate")
        if(dates && !getCheckedBoxes("checkAll"))
        {  
            dates.map(d => ConfirmarInscripcion(d.toString()))
        }else{  
           ConfirmarInscripcion(null);
        }
    }
    const ConfirmarInscripcion=(fecha)=>{

        
        var formData = new FormData();

        formData.append("idActividad", idActividad);
        formData.append("idSocio", socio.id);
        if(fecha){
        var split = fecha.split("/");
        formData.append("asistencia",""+split[2].trim()+"-"+split[1]+"-"+split[0]+ " 23:59:59")
        }
        var result = Inscribir(formData);

        if(result == "true"){

            setInscripto(true);
        }else{

            setInscripto(false);
            alert('No fue posible inscribirte en esta actividad'+ (fecha? ' el dia '+fecha : '')+': '+result)
        }
    }
    const VerMisActividades= ()=>{
    window.location.href= "/mis-actividades";
}
    const CargarDatos=()=>{
        var dniUser = document.getElementById("dni").value;
        if(dniUser){
        setDni(dniUser);
        var socio = GetSocioDNI(dniUser);
        setSocio(socio);  
        }else{
            alert('Por favor: ingresa tu dni')
        }
    }
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{display: 'flex',flexWrap: 'wrap', width:'100%'}}>
            </div>
            {!socio &&<div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <Typography variant="h4" component="h4">
            Estas a punto de inscribirte en la actividad: {categoria.nombre}
          </Typography>
            </div> } 
            <div style={{flexBasis: '100%'}}></div> 

            {/* aca buscador */}
            {!socio &&  <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <TextField label="Ingresá tu DNI" id="dni"  />
             <Button variant="contained" size="large" color="primary" onClick={CargarDatos} style={{ background:'#7c3259', marginLeft: 10}}>
               Continuar
                </Button>
            </div> } 
            {socio && !inscripto && <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <Typography variant="h4" component="h4">¡Hola {socio.nombre + " " +socio.apellido}!</Typography><br />
            <div style={{flexBasis: '100%'}}></div> 
            <div style={{textAlign: 'center'}}>{"Estas a punto de inscribirte en "+ categoria.nombre+ " en los siguientes horarios: "  }</div> 
            <div style={{flexBasis: '100%'}}></div> 
            {actividad.horarios.map(a => (<Fragment><div>{getHorario(a)}</div><div style={{flexBasis: '100%'}}></div> </Fragment> ))}
            <div style={{flexBasis: '100%'}}></div> 
            <Typography variant="h6" component="h6">Elegí un dia:</Typography><br />
            <div style={{flexBasis: '100%'}}></div> 
            {proximasClases.map(d => (<Fragment>
                <label  style={{fontSize:18}}><input type="checkbox" id="cbox1" style={{marginTop:10, height:15, width:15}} name="checkDate" value={formatDate(d)} /> {GetDiaJs(d.getDay())} {d.getDate()}</label>
                <div style={{flexBasis: '100%'}}></div> 
                </Fragment> ))}
                <div style={{flexBasis: '100%'}}></div> 
            <Typography variant="h6" component="h6">o</Typography><br />    
            <div style={{flexBasis: '100%'}}></div> 
            <label style={{fontSize:18}}><input type="checkbox" id="cbox1" name="checkAll" style={{marginTop:10, height:15, width:15}} value="first_checkbox" /> Anotarme a todas las clases</label>
            <div style={{flexBasis: '100%'}}></div> 
            <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <Button variant="contained" onClick={ConfirmarInscripcionTest} size="large" color="primary" style={{ background:'#7c3259', marginLeft: 10}}>
               Confirmar inscripcion
                </Button>
            </div>

            </div> }


            {socio && inscripto && <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <Typography variant="h4" component="h4">¡Inscripcion exitosa!</Typography><br />
            <div style={{flexBasis: '100%'}}></div> 
            <div className="details-page" style={{marginLeft:'0',display: 'flex',flexWrap: 'wrap'}}>
            <Button variant="contained" onClick={VerMisActividades} size="large" color="primary" style={{ background:'#7c3259', marginLeft: 10}}>
               Ver mis actividades
                </Button>
            </div>

            </div> }
            <div style={{flexBasis: '100%'}}></div> 
        </Fragment>
    );
}

export default Page;