import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBar';
import {
    GetPagos, PostPago,DeletePago
} from '../../utils/PagosManager';
import {
    UsuarioLogueado
} from '../../utils/UserManager';
import {
    GetCategorias
} from '../../utils/CategoriasManager';
import {
    parse_query_string
} from '../../utils/uri';

class SocioPagos extends Component {
    constructor(props) {
        super(props);
        this.state = {pagos :[],show:false,categoriasLookUp:[],idSocio:null}
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
    if(!UsuarioLogueado()){
        window.location.href = "/login";
    } else
    {
     var categorias = GetCategorias();
     var categoriasLookUp = categorias.reduce(function(acc, cur, i) {
        acc[cur.id] = cur.nombre;
        return acc;
        }, {});
      var queryParams = parse_query_string(window.location.search);
      let idSocio = queryParams["?idSocio"];
      let nombreSocio = queryParams["nombre"];
      let pagos = GetPagos(idSocio);
     this.setState({pagos, show:true,categoriasLookUp,idSocio, nombreSocio}) 
    }
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar DrawerOpenedDefault={true} goTo={this.goTo} path='/socio-pagos' />
            {this.state.show &&<Page
                currentItem={currentItem}
                pagos={this.state.pagos}
                onCreate={PostPago}
                categoriasLookUp={this.state.categoriasLookUp}
                onDelete={DeletePago}
                nombreSocio={this.state.nombreSocio}
                goTo={this.goTo}
                idSocio={this.state.idSocio}
            />}
            {!this.state.show && <span>Cargando socios...</span>}
            </React.Fragment>
        );
    }
}


export default SocioPagos;