import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBar';
import {
    setItemStorage, getItemStorage,
} from '../../utils/LocalStorageManager';
import {
    GetSocios, PostSocio
} from '../../utils/SocioManager';
import MaterialTable from 'material-table';


class Actividad extends Component {
    constructor(props) {
        super(props);
        this.state = {socios :[],show:false}
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
     let socios = GetSocios();
     console.log(socios);
     this.setState({socios, show:true})
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar DrawerOpenedDefault={true} goTo={this.goTo} path='/socios' />
            {this.state.show &&<Page
                currentItem={currentItem}
                socios={this.state.socios}
                onCreate={PostSocio}
                goTo={this.goTo}
            />}
            {!this.state.show && <span>Cargando socios...</span>}
            </React.Fragment>
        );
    }
}


export default Actividad;