import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from 'material-table';
import {
  GetActividade,GetCategorias
} from '../../utils/CategoriasManager';

import {
  DeleteActividad
} from '../../utils/ActividadesHelper';
import ListItems from '../common/ListItem';

import Button from '@material-ui/core/Button';
import {
  GetDia
} from '../../utils/DiasHelper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';


function Page(props) {
    const {
        // goTo,
        // currentItem,
    } = props;
    const getHorarios=(horarios)=>{
      var stringHorario = "";
      horarios.map(h =>{
          stringHorario =stringHorario + GetDia(h.dia) +" de "+h.horaDesde+ " a "+h.horaHasta+". "
      })
      return stringHorario;
  }
    const actividades = GetActividade();
    const categorias = GetCategorias();
    const getCategoria = id =>{
        let categoria = categorias.filter(c => c.id == id)[0];
        return categoria.nombre;
      };

    const items = actividades.map(i  =>{
        return {
          actividad:getCategoria(i.categoria),
          id: i.id,
          descripcion: i.descripcion,
          horarios: getHorarios(i.horarios),
          delete:<DeleteIcon style={{cursor: 'pointer'}} onClick={() =>  borrarActividad(i.id)} />,
          edit:<EditIcon style={{cursor: 'pointer'}}  onClick={() =>  window.location.href = "/editar-actividad/"+i.id} />,
          inscriptos:<AddIcon style={{cursor: 'pointer'}}  onClick={() => irActividad(i.id)} />,

        }});
    const borrarActividad = id =>{
          if(window.confirm("Seguro que queres borrar esta actividad?"))
                DeleteActividad(id)
    setState({
      columns: [
        { title: 'Actividad', field: 'actividad', editable: 'never' },   
        { title: 'Descripcion', field: 'descripcion', editable: 'never' },   
        { title: 'Horarios', field: 'horarios', editable: 'never' },  
        { title: 'Editar', field: 'edit', editable: 'never' },   
        { title: 'Eliminar', field: 'delete', editable: 'never' },   
        { title: 'Ver Inscriptos', field: 'inscriptos', editable: 'never' },   
      ],
      data: actividades.filter(a => a.id != id ).map(i  =>{
        return {
          actividad:getCategoria(i.categoria),
          id: i.id,
          descripcion: i.descripcion,
          horarios: getHorarios(i.horarios),
          delete:<DeleteIcon style={{cursor: 'pointer'}}  onClick={() =>  borrarActividad(i.id)} />,
          edit:<EditIcon style={{cursor: 'pointer'}}  onClick={() =>  window.location.href = "/editar-actividad/"+i.id} />,
          inscriptos:<AddIcon style={{cursor: 'pointer'}}  onClick={() => irActividad(i.id)} />,

        }}),
    });
  }
    const IrActividades= () =>{
          window.location.href = "/crear-actividad";
          }
    const irActividad= (id) =>{
            window.location.href = "/actividad/"+id;
            }
    const [state, setState] = React.useState({
          columns: [
            { title: 'Actividad', field: 'actividad', editable: 'never' },   
            { title: 'Descripcion', field: 'descripcion', editable: 'never' },   
            { title: 'Horarios', field: 'horarios', editable: 'never' },  
            { title: 'Editar', field: 'edit', editable: 'never' },   
            { title: 'Eliminar', field: 'delete', editable: 'never' },   
            { title: 'Ver Inscriptos', field: 'inscriptos', editable: 'never' },   



          ],
          data: items,
        });
      
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>
            <Button variant="contained" onClick={IrActividades} style={{width: '210px'}} color="primary" size="large" >Crear actividad</Button>

            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 

            {/* <ListItems items={items} /> */}
            <MaterialTable
                title="Actividades"
                columns={state.columns}
                data={state.data}
                style={{width:'100%'}}
            />
            </div>

        </Fragment>
    );
}

export default Page;