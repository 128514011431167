import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    LoguearUsuario
} from '../../utils/UserManager';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

function Page(props) {
    const {
        // goTo,
        // currentItem,
    } = props;
    const useStyles = makeStyles((theme) => ({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
      }));
      
    const classes = useStyles();
   const Loguear = () =>{
    var usuario = document.getElementById("user").value;
    var password = document.getElementById("password").value;
    if(usuario == "admin" && password == "owenKpo"){
        LoguearUsuario({usuario});
        window.location.href = "/socios";
     }else {
         alert('Usuario incorrecto')
     }
    }
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>

            <div class="break" style={{flexBasis: '100%'}}></div> 
            <FormControl variant="outlined"  className={classes.formControl}>
           
           <TextField id="user" label="Usuario" variant="outlined"  />
            </FormControl>
            
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <FormControl variant="outlined"  className={classes.formControl}>
           
           <TextField id="password" label="Contraseña"  variant="outlined" type="password" />
            </FormControl>
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <Button variant="contained" onClick={Loguear}  style={{width: '210px'}} color="primary" size="large" > Iniciar sesion</Button>
            <div style={{flexBasis: '100%', paddingTop:10}}></div> 
            </div>
        </Fragment>
    );
}

export default Page;