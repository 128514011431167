import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBarOld';



class Categoria extends Component {
    constructor(props) {
        super(props);

        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {

    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar goTo={this.goTo}/>
            <Page
                currentItem={currentItem}
                goTo={this.goTo}
            />
            </React.Fragment>
        );
    }
}


export default Categoria;