import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './style.css';

function Page(props) {
    const {
        goTo,
        currentItem,
        lunes,
        martes,
        miercoles,
        jueves,
        viernes,
        sabado,
        domingo
    } = props;
     
    
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{display: 'flex',flexWrap: 'wrap', width:'100%'}}>
            </div>
            <div>
            {lunes && <div className="containerColumna">
            {lunes && <div className="diaTitulo">Lunes</div>}
            {lunes.map(d => {
                return <Fragment>                 
                    <div className="horario">{d.horaDesde}</div>
                    <div className="actividad">{d.nombre} {d.descripcion}</div>
                    </Fragment>
            })}
            </div>}
            {martes && <div className="containerColumna">
            {martes && <div className="diaTitulo">Martes</div>}
            {martes.map(d => {
                return <Fragment>                 
                    <div className="horario">{d.horaDesde}</div>
                    <div className="actividad">{d.nombre} {d.descripcion}</div>
                    </Fragment>
            })}
            
            </div>}

            {miercoles && <div className="containerColumna">
            {miercoles && <div className="diaTitulo">Miercoles</div>}
            {miercoles.map(d => {
                return <Fragment>                 
                    <div className="horario">{d.horaDesde}</div>
                    <div className="actividad">{d.nombre} {d.descripcion}</div>
                    </Fragment>
            })}
            
            </div>}

            {jueves && <div className="containerColumna">
            {jueves && <div className="diaTitulo">Jueves</div>}
            {jueves.map(d => {
                return <Fragment>                 
                    <div className="horario">{d.horaDesde}</div>
                    <div className="actividad">{d.nombre} {d.descripcion}</div>
                    </Fragment>
            })}
            
            </div>}

            {viernes && <div className="containerColumna">
            {viernes && <div className="diaTitulo">Viernes</div>}
            {viernes.map(d => {
                return <Fragment>                 
                    <div className="horario">{d.horaDesde}</div>
                    <div className="actividad">{d.nombre} {d.descripcion}</div>
                    </Fragment>
            })}
            
            </div>}

            {sabado && <div className="containerColumna">
            {sabado && <div className="diaTitulo">Sabado</div>}
            {sabado.map(d => {
                return <Fragment>                 
                    <div className="horario">{d.horaDesde}</div>
                    <div className="actividad">{d.nombre} {d.descripcion}</div>
                    </Fragment>
            })}
            
            </div>}

            {/* {domingo && <div className="containerColumna">
            {domingo && <div className="diaTitulo">sabado</div>}
            {domingo.map(d => {
                return <Fragment>                 
                    <div className="horario">{d.horaDesde}</div>
                    <div className="actividad">{d.nombre}</div>
                    </Fragment>
            })}
            
            </div>} */}
            </div>
            <div style={{flexBasis: '100%'}}></div> 
        </Fragment>
    );
}

export default Page;